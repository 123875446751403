/*
 |--------------------------------------------------------------------------
 | FORMS
 | 2015-11-15
 |--------------------------------------------------------------------------
 */
input.dragover,
input.dragover:disabled {
    background: transparent -moz-linear-gradient(top left, #ffffff, #d0fbcc) repeat scroll 0% 0%;
}

form label {
    display: none;
}
.form-label{
    display:block;
    min-width: 150px;
}

form select {
    padding: 5px;
    margin: 0;
    outline: none;
    display: block;
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    border-radius: 0px;

    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #cccccc;
}

form span.input-group-addon > div {
    width: 150px;
}

form span.input-group-addon > div.double-wide {
    min-width: 300px;
    width: auto;
}

form .input-group-addon.above {
    display: block;
    clear: both;
    width: 100%;

    border-bottom: none;
    border-right: 1px solid #CCC;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

form .has-success .input-group-addon.above {
    border-right: 1px solid #3c763d;
}

form .input-group-btn.bellow {
    display: block;
    clear: both;
    width: 100%;

    padding: 0px;
}

form .input-group-btn.bellow {
    position: relative;
    left: 1px;
}

form .input-group-btn.bellow > * {
    margin: 0px;
    border-top: none;
    border-radius: 0px;
}

form .input-group-btn.bellow > *:last-child {
    border-bottom-right-radius: 4px;
}

form .input-group-btn.bellow > *:first-child {
    border-bottom-left-radius: 4px;
}

form .minify {
    line-height: 1em;
    font-size: .9em;
}

.input-group-btn button,
.input-group-btn a {
    text-transform: lowercase;
}

.input-group-addon + .bootstrap-switch-wrapper {
    position: relative;
    float: left;
}

.image_preview {
    width: 100%;
    height: 120px;
    background-position: center center;
    background-repeat: no-repeat;

    border-left: 1px solid #cccccc;
    border-top: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
}

.image_preview span{
    position: relative;
    top: -24px;
    left: 7px;
}

.gallerycontroller input{
    -webkit-border-radius: 0px !important;
    -moz-border-radius: 0px !important;
    border-radius: 0px !important;
}

.well-control{
    margin-top: 20px;
}

.thumbnailPickerImage {
    display: block;
    width: 100%;
    height: 250px;
    background-size: cover;

    margin-bottom: 7px;


}
.thumbnailPickerImage.selected{
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 10px #007bff;
}

.btn:active,
.btn:focus {
    outline: none !important;
    box-shadow: none !important;
}